import * as firebase from "firebase";
import Vue from 'vue';
import {AUTH_REQUEST, AUTH_LOGOUT, AUTH_SUCCESS, AUTH_ERROR, AUTH_SET_ID, AUTH_SET_USER} from '../constants';
import createAuth0Client from "@auth0/auth0-spa-js";

import {getAxios} from '../../api'
const axios = getAxios();

import store from "../index";

let authClient;

let clientInitializedHook;

createAuth0Client({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
    redirect_uri: window.location.protocol + "//" + window.location.host + "/",
    audience: process.env.VUE_APP_AUTH0_AUDIENCE
}).then(client => {
        authClient = client;
        window.console.log("Got Client");
        if (clientInitializedHook != null) {
            clientInitializedHook().then(() => {
                window.console.log("Should be success")
            }).catch(err => {
                window.console.log(err)
            });
        }
    }
);

axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

axios.interceptors.response.use((response) => {
        return response
    }, async function(error) {
        window.console.warn("ERROR",error.response.status);
        if (error.response.status == 401){
            localStorage.removeItem('user-token');
            if (authClient == null){
                clientInitializedHook = async () => {
                    await store.dispatch(AUTH_REQUEST);
                }
            }else {
                await store.dispatch(AUTH_REQUEST);
            }
        }
        return Promise.reject(error);
    }
);

const state = {
    auth_token: localStorage.getItem('user-token') || '',
    auth_status: '',
    auth_user: {},
    auth_expire_at: null,
    auth_id: null,
    auth_permissions: [],
};

const getters = {
    isAuthenticated: state => !!state.auth_token,
    authStatus: state => state.auth_status,
    getAuthId: state => state.auth_id,
    getPermissions: state => state.auth_permissions,
    hasPermission: (state) => (permission) => {
        if (state.auth_permissions.length > 0) {
            return !!state.auth_permissions.find(el => el.permission_name == permission)
        }
        return false
    }
};

const actions = {
    async [AUTH_REQUEST]({commit, dispatch}) {
        try {
            commit(AUTH_REQUEST);
            await authClient.loginWithPopup();
            const token = await authClient.getTokenSilently();
            const user = await authClient.getUser();
            localStorage.setItem('user-token', token);
            localStorage.setItem('user-id', user.sub);
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;
            commit(AUTH_SET_ID, user.sub);
            commit(AUTH_SET_USER, user);
            commit(AUTH_SUCCESS, token);
            dispatch('AUTH_GET_ME');
        } catch (err) {
            commit(AUTH_ERROR, err);
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-sub');
        }
    },
    async [AUTH_LOGOUT]({commit}, where) {
        commit(AUTH_LOGOUT);
        const token = localStorage.getItem('user-token');
        const registration_token = await firebase.messaging().getToken();
        Vue.prototype.$socket.send(JSON.stringify({
            "method": "unsubscribe_leader",
            "data":
                {"token": token, "registration_token": registration_token}
        }))
        localStorage.removeItem('user-token');
        localStorage.removeItem('user-sub');
        delete axios.defaults.headers.common['Authorization'];
        commit(AUTH_LOGOUT);
        commit('AUTH_SET_PERMISSIONS',[]);
        await authClient.logout(where);
    },
    async ['AUTH_GET_ME']({commit}) {
        commit('AUTH_LOADING');
        try{
            const resp = await axios.get('me');
            commit('AUTH_SET_PERMISSIONS', resp.data.permissions);
        }catch (e) {
            commit(AUTH_ERROR, e);
        }
    }
};

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.auth_status = 'loading';
    },
    ['AUTH_LOADING']: (state) => {
        state.auth_status = 'loading';
    },
    ['AUTH_SET_PERMISSIONS']:(state, permissions) =>{
        state.auth_status = 'success';
        state.auth_permissions = JSON.parse(JSON.stringify(permissions));
    },
    [AUTH_SUCCESS]: (state, token) => {
        state.auth_status = 'success';
        state.auth_token = token
    },
    [AUTH_SET_ID]: (state, id) => {
        state.auth_id = id
    },
    [AUTH_SET_USER]: (state, user) => {
        state.auth_user = user
    },
    [AUTH_ERROR]: (state) => {
        state.auth_status = 'error';
    },
    [AUTH_LOGOUT]: (state) => {
        state.auth_token = '';
        state.auth_status = '';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
