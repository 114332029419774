import {getAxios} from '../../api'
const axios = getAxios();

const state = {
    status: String,
    nutrition: [],
};

const getters = {
    getNutritionStatus: state => {
        return state.status;
    },
    getNutritionInstructions: state => {
        return state.nutrition;
    },
    getSpecificNutritionInstructions: state => role => {
        return state.nutrition.filter(el=> el.role == role)
    },
    getNutrition: state => id => {
        return state.nutrition.find(el=> el.id == id)
    }
};

const actions = {
    async ['NUTRITION_INSTRUCTIONS_GET']({commit}) {
        commit('NUTRITION_INSTRUCTIONS_LOADING');
        try {
            const resp = await axios.get('nutrition/');
            commit('NUTRITION_INSTRUCTIONS_GET_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('NUTRITION_INSTRUCTIONS_ERROR');
            return e.response
        }
    },
    async['NUTRITION_INSTRUCTIONS_CREATE']({commit}, nutrition) {
        try {
                commit('NUTRITION_INSTRUCTIONS_LOADING');
                const resp = await axios.post('nutrition/', nutrition);
                commit('NUTRITION_INSTRUCTIONS_CREATE_SUCCESS', resp.data);
                return resp;
        } catch (e) {
            commit('NUTRITION_INSTRUCTIONS_ERROR');
            return e.response
        }
    },
    async['NUTRITION_INSTRUCTIONS_LIST_CREATE']({commit}, nutritions) {
        try {
            commit('NUTRITION_INSTRUCTIONS_LOADING');
            const resp = await axios.put('nutrition/', nutritions);
            commit('NUTRITION_INSTRUCTIONS_LIST_CREATE_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('NUTRITION_INSTRUCTIONS_ERROR');
            return e.response
        }
    },
    async['NUTRITION_INSTRUCTIONS_UPDATE']({commit}, nutrition) {
        commit('NUTRITION_INSTRUCTIONS_LOADING');
        try {
            let data = Object.assign({}, nutrition);
            delete data['id'];
            const resp = await axios.patch('nutrition/'+ nutrition.id, data);
            commit('NUTRITION_INSTRUCTIONS_UPDATE_SUCCESS', nutrition);
            return resp;
        } catch (e) {
            commit('NUTRITION_INSTRUCTIONS_ERROR');
            return e.response
        }
    },
    async ['NUTRITION_INSTRUCTION_REMOVE']({commit}, id) {
        commit('NUTRITION_INSTRUCTIONS_LOADING');
        try {
            const resp = await axios.delete('nutrition/'+ id);
            commit('NUTRITION_INSTRUCTIONS_DELETE_SUCCESS', id);
            return resp;
        } catch (e) {
            commit('NUTRITION_INSTRUCTIONS_ERROR');
            return e.response
        }
    }
};

const mutations = {
    ['NUTRITION_INSTRUCTIONS_LOADING'](state) {
        state.status = 'loading'
    },
    ['NUTRITION_INSTRUCTIONS_SUCCESS'](state) {
        state.status = 'success'
    },
    ['NUTRITION_INSTRUCTIONS_ERROR'](state) {
        state.status = 'error'
    },
    ['NUTRITION_INSTRUCTIONS_GET_SUCCESS'](state, payload) {
        state.status = 'success';
        state.nutrition = payload;
    },
    ['NUTRITION_INSTRUCTIONS_CREATE_SUCCESS'](state, data) {
        state.status='success';
        state.nutrition.push(data);
    },
    ['NUTRITION_INSTRUCTIONS_LIST_CREATE_SUCCESS'](state, data) {
        state.status='success';
        state.nutrition.push(data);
    },
    ['NUTRITION_INSTRUCTIONS_DELETE_SUCCESS'](state, id) {
        state.status = 'success';
        const nutritions = state.nutrition.filter(el=>el.id != id);
        state.nutrition = nutritions;
    },
    ['NUTRITION_INSTRUCTIONS_UPDATE_SUCCESS'](state, nutrition) {
        state.status = 'success';
        const id = nutrition.id;
        const nutritions = Object.assign([],state.nutrition.filter(el=>el.id != id));
        nutritions.push(nutrition);
        state.nutrition = nutritions;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
