import {getAxios} from '../../api'

const axios = getAxios();

const state = {
    states_machine_status: 'success',
    states_machine_states: [],
};

const getters = {
    statesMachineGetStates: state => state.states_machine_states,
};

const actions = {
    async ['STATES_MACHINE_GET']({commit}) {
        try {
            const str = 'triggers/';
            const resp = await axios.get(str);
            this._vm.$log.debug(resp);
            commit("STATES_MACHINE_GET_SUCCESS", resp.data);//.map((trigger) => {
                //trigger['condition'] = adoptRootCondition(trigger['condition'])
                //return trigger
            //}));
        } catch (e) {
            this._vm.$log.error(e.response);
        }
    },
    async ['STATES_MACHINE_STATES_CREATE']({commit}, condition) {
        try {
            const str = 'triggers/';
            const resp = await axios.post(str, condition);
            this._vm.$log.debug(resp);
            //commit("STATES_MACHINE_POST_SUCCESS", resp.data);
            commit('STATES_MACHINE_STATES_CREATE', resp.data);
            return resp.data;
        } catch (e) {
            this._vm.$log.error(e.response);
        }
    },
    async ['STATES_MACHINE_STATES_EDIT']({commit}, condition) {
        try {
            const str = 'triggers/'+ condition.id+'/';
            if (condition.hasOwnProperty('owner')) {
                delete condition.owner
            }
            const resp = await axios.put(str, condition);
            this._vm.$log.debug(resp);
            commit('STATES_MACHINE_STATES_EDIT', condition);
            return resp.data
        } catch (e) {
            this._vm.$log.error(e.response);
        }
    },
    async ['STATES_MACHINE_DELETE']({commit}, id) {
        try {
            const str = 'triggers/' + id+'/';
            const resp = await axios.delete(str);
            this._vm.$log.debug(resp);
            commit('STATES_MACHINE_DELETE_SUCCESS', id);
        } catch (e) {
            this._vm.$log.error(e.response);
        }
    }
};

const mutations = {
    ['STATES_MACHINE_GET_SUCCESS'](store, conditions) {
        store.states_machine_status = 'success';
        store.states_machine_states = conditions;
    },
    ['STATES_MACHINE_STATES_CREATE'](store, condition) {
        store.states_machine_status = 'success';
        store.states_machine_states.push(condition);
    },
    ['STATES_MACHINE_STATES_EDIT'](store, data) {
        let states_machine_states = [...state.states_machine_states.filter(el=> el.id !== data.id)];
        states_machine_states.push(data)
        store.states_machine_status = 'success';
        //states_machine_states = states_machine_states.sort((a, b) => a.id - b.id)
        store.states_machine_states = states_machine_states;
    },
    ['STATES_MACHINE_DELETE_SUCCESS'](store, id) {
        let states = JSON.parse(JSON.stringify(store.states_machine_states))
        store.states_machine_states = [];
        let index = undefined;
        for (let i in states) {
            if (states[i].id === id) {
                index = i;
            }
        }
        if (index !== undefined) {
            states.splice(index, 1);
        }
        store.states_machine_states = JSON.parse(JSON.stringify(states));
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}