<template>
    <div id="app">
        <div v-if="!isWide" class="lifetec_navbar">
            <b-button @click="isOpen=true" type="is-small">
                <img style="height: 24px; width: 24px;" src="./assets/svg/menu.svg">
            </b-button>
        </div>
        <div class="sidebar-page">
            <section class="sidebar-layout">
                <main-menu-sidebar
                        :is-wide="isWide"
                        :is-open="isOpen"
                        @close="isOpen=false"/>
                <div style="width: 100%">
                    <div id="maincontainer">
                        <router-view/>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    //import MainMenu from "./views/MainMenu";
    import MainMenuSidebar from "./views/MainMenuSidebar";

    export default {
        components: {
      //      MainMenu,
            MainMenuSidebar,
        },
        data() {
            return {
                isWide: true,
                isOpen: false,
            }
        },
        created() {
            window.addEventListener('resize', this.resize);
            this.resize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize);
        },
        mounted() {
            setTimeout(this.TestSocket, 10000);

        },
        methods: {
            TestSocket() {
                this.$store.dispatch('SOCKET_TEST');
                setTimeout(this.TestSocket, 15000);
            },
            resize() {
                this.isWide = window.innerWidth > 768;
            }
        },
    }
</script>

<style lang="scss">

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        background-color: #F8F8F8;
        height: 100vh;
    }

    .lifetec_navbar {
        position: fixed;
        text-align: left;
        height: 30px;
        padding: 3px;
    }

    #maincontainer {
        padding-left: 85px;
        padding-top: 0px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
    }

    @media (max-width: 768px) {
        #maincontainer {
            padding-left: 0;
            padding-top: 35px;
        }
    }

    .sidebar-page {
        display: flex;
        flex-direction: column;
        width: 100%;
        //min-height: 100%;
        min-height: 100vh;
        .sidebar-layout {
            display: flex;
            flex-direction: row;
            //min-height: 100%;
             min-height: 100vh;
        }

    }
</style>