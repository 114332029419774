import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return
  }
  next('/')
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return
  }
  next('/blocked');
};


const routes = [
  {
    path: '',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/questions',
    name: 'questions',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/QuestionsPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/questionary',
    name: 'questionary',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/QuestionaryPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/UsersPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/helsinki_users',
    name: 'helsinki_users',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/HelsinkiUsersPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/client/:id/stages',
    name: 'clientstages',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/ClientStagesPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/client/:id',
    name: 'client',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/ClientPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/chat/:room',
    name: 'chat',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/ClientChatPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/stages',
    name: 'stagesadmin',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/StagesAdminPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/states',
    name: 'states',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/StatesMachinePage'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/nutritions',
    name: 'nutritions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NutritionInstructionsPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/chat_templates',
    name: 'chat_templates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatTemplatesPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/medicine',
    name: 'medicine',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MedicineAdminPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/supplements',
    name: 'supplements',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SupplementsAdminPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/support',
    name: 'support',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SupportPage.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/blocked',
    name: 'blocked',
    component: () => import(/* webpackChunkName: "navigation" */ '../views/BlockedPage.vue'),
    beforeEnter: ifNotAuthenticated,
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router
