import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    client_medicine_status: String,
    client_medicine: [],
};

const getters = {
    getClientMedicineInstructions: state => state.client_medicine,
    getClientMedicineInstruction: state => medicineId => {
        return state.client_medicine.find(el => el.item_id === medicineId)
    }
};

const actions = {
    async ['CLIENT_MEDICINE_INSTRUCTION_GET']({commit}, user_id) {
        commit('CLIENT_MEDICINE_LOADING');
        try {
            const str = 'clients/' + user_id + '/medicine';
            const resp = await axios.get(str);
            commit('CLIENT_MEDICINE_INSTRUCTION_GET_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('CLIENT_MEDICINE_ERROR');
            return e.response
        }
    },
    async ['CLIENT_MEDICINE_INSTRUCTION_UPDATE']({commit}, {user, instruction}) {
        commit('CLIENT_MEDICINE_LOADING');
        try {
            const query = 'clients/'+ user + '/medicine/'+ instruction.item_id;
            const assigned = instruction.assigned;
            if (assigned === 0) await axios.delete(query);
            if (assigned === 1) await axios.post(query);
            commit('CLIENT_MEDICINE_INSTRUCTION_UPDATE_SUCCESS', instruction);
        }catch (e) {
            commit('CLIENT_MEDICINE_ERROR');
            return e.response
        }
    },
};

const mutations = {
    ['CLIENT_MEDICINE_LOADING'](state){
        state.client_medicine_status = 'loading';
    },
    ['CLIENT_MEDICINE_ERROR'](state) {
        state.client_medicine_status = 'error';
    },
    ['CLIENT_MEDICINE_INSTRUCTION_GET_SUCCESS'](state, data) {
        state.client_medicine_status = 'success';
        state.client_medicine = data;
    },
    ['CLIENT_MEDICINE_INSTRUCTION_UPDATE_SUCCESS'](state, data) {
        state.client_medicine_status='success';
        let nutrition = [...state.client_medicine.filter(el=> el.item_id !== data.item_id)];
        state.client_medicine = [];
        nutrition.push(data);
        state.client_medicine = nutrition;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
