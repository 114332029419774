
import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    supplements_items_status: 'disconnected',
    supplements_items:{}
}

const getters = {
    getSupplementsItemsStatus: state => {
        return state.supplements_items_status
    },
    getSupplementsItems: state => {
        return state.supplements_items
    }
}

const actions = {
    async ['SUPPLEMENTS_ITEMS_LIST_GET']({commit}) {
        commit('SUPPLEMENTS_ITEMS_LOADING');
        try {
            const resp = await axios.get('reports/supplements_items');
            commit('SUPPLEMENTS_ITEMS_GET_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('SUPPLEMENTS_ITEMS_ERROR');
            return e.response
        }
    },
    async ["SUPPLEMENTS_ITEMS_CREATE"]({commit}, item) {
        commit('SUPPLEMENTS_ITEMS_LOADING');
        try {
            const resp = await axios.post('reports/supplements_items', item);
            commit('SUPPLEMENTS_ITEMS_CREATE_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('SUPPLEMENTS_ITEMS_ERROR');
            return e.response
        }
    },
    async ["SUPPLEMENTS_ITEMS_EDIT"]({commit}, item) {
        commit('SUPPLEMENTS_ITEMS_LOADING');
        try {
            const resp = await axios.post('reports/supplements_item/'+ item.id, item);
            commit('SUPPLEMENTS_ITEMS_EDIT_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('SUPPLEMENTS_ITEMS_ERROR');
            return e.response
        }
    },
    async ["SUPPLEMENTS_ITEMS_DELETE"]({commit}, id) {
        commit('SUPPLEMENTS_ITEMS_LOADING');
        try {
            const resp = await axios.delete('reports/supplements_item/'+ id);
            commit('SUPPLEMENTS_ITEMS_DELETE_SUCCESS', id);
            return resp;
        } catch (e) {
            commit('SUPPLEMENTS_ITEMS_ERROR');
            return e.response
        }
    },
}


const mutations = {
    ['SUPPLEMENTS_ITEMS_LOADING']: (state) => {
        state.supplements_items_status = 'loading';
    },
    ['SUPPLEMENTS_ITEMS_GET_SUCCESS']: (state, payload) => {
        state.supplements_items_status = 'success';
        state.supplements_items = payload;
    },
    ['SUPPLEMENTS_ITEMS_ERROR']:(state) => {
        state.supplements_items_status = 'error';
    },
    ['SUPPLEMENTS_ITEMS_CREATE_SUCCESS']: (state, payload) => {
        state.supplements_items.push(payload)
    },
    ['SUPPLEMENTS_ITEMS_EDIT_SUCCESS']: (state, payload) => {
        let supplements_items = JSON.parse(JSON.stringify(state.supplements_items))
        let id = payload.id
        supplements_items = supplements_items.filter(function (el) {if (el.id !== id) return el})
        supplements_items.push(payload)
        state.supplements_items= supplements_items
        state.supplements_items_status='success'
    },
    ['SUPPLEMENTS_ITEMS_DELETE_SUCCESS']: (state, id ) => {
        let supplements_items = JSON.parse(JSON.stringify(state.supplements_items))
        supplements_items = supplements_items.filter(function (el) {if (el.id !== id) return el})
        state.supplements_items= supplements_items
        state.supplements_items_status='success'
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};