import {getAxios} from '../../api'

const axios = getAxios();

const state = {
    chat_templates_status: 'ok',
    chat_templates_categories: [],
    chat_templates: [],
};

const getters = {
    getChatTemplateStatus: state => state.chat_templates_status,
    getChatTemplatesCategories: state => Object.values(state.chat_templates_categories),
    getChatTemplatesCategory: state => (id) => state.chat_templates_categories[id],
    getChatTemplates: state => (id) => Object.values(state.chat_templates)
        .filter(template =>template.category_id === id)
        .sort(function (a,b){return a.name.localeCompare(b.name)}),
    getChatTemplate: state => (id) => state.chat_templates[id]
};

const actions = {
    async ['CHAT_TEMPLATES_CATEGORIES_GET']({commit}) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.get('chat/templates_category');
            commit('CHAT_TEMPLATES_CATEGORIES_SET', resp.data);
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_GET']({commit}) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.get('chat/templates_category');
            commit('CHAT_TEMPLATES_SET', resp.data);
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_ADD']({commit}, {template, category_id}) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.post('chat/templates_category/' + category_id + '/template', template);
            commit('CHAT_TEMPLATES_STATUS_SET', 'ok');
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_CATEGORY_ADD']({commit}, category) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.post('chat/templates_category', category);
            commit('CHAT_TEMPLATES_STATUS_SET', 'ok');
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_CATEGORY_REMOVE']({commit}, category) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.delete('chat/templates_category/' + category.id);
            commit('CHAT_TEMPLATES_STATUS_SET', 'ok');
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_REMOVE']({commit}, {category_id, id}) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.delete('chat/templates_category/' + category_id + '/template/' + id);
            commit('CHAT_TEMPLATES_STATUS_SET', 'ok');
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_MOVE']({commit}, {fromTemplate, toCategory}) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const data = { category_id:toCategory };
            const resp = await axios.put('chat/templates_category/'+ toCategory+'/template/'+ fromTemplate, data);
            commit('CHAT_TEMPLATES_MOVE_SUCCESS', data);
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_UPDATE']({commit}, {template, category_id}) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.put('chat/templates_category/' + category_id + '/template/' + template.id, template);
            commit('CHAT_TEMPLATES_STATUS_SET', 'ok');
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
    async ['CHAT_TEMPLATES_CATEGORY_UPDATE']({commit}, category) {
        commit('CHAT_TEMPLATES_STATUS_SET', 'loading');
        try {
            const resp = await axios.put('chat/templates_category/' + category.id, category);
            commit('CHAT_TEMPLATES_STATUS_SET', 'ok');
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHAT_TEMPLATES_STATUS_SET', 'error');
            return e.response;
        }
    },
};

const mutations = {
    ['CHAT_TEMPLATES_STATUS_SET'](store, state) {
        store.chat_templates_state = state;
    },
    ['CHAT_TEMPLATES_CATEGORIES_SET'](store, data) {
        let categories = {};
        let templates = {};
        data.map(item => {
            categories[item.id] = item;
            item.templates.map(template => {
                templates[template.id] = template
            })
        });
        state.chat_templates_categories = categories;
        state.chat_templates = templates;
        state.chat_templates_status = 'success';
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}