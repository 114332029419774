<template>
    <b-sidebar class="sidebar"
               :position="position"
               :mobile="mobile"
               :expand-on-hover="expandOnHover"
               :reduce="reduce"
               :fullheight="fullHeight"
               :open="isOpen||isWide"
               :overlay="overlay"
               :can-cancel="canCancel"
               @close="$emit('close')">
        <div class="p-1">
            <b-menu class="is-custom-mobile">
                <b-menu-list label="Menu">
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:clients')||hasPermission('read:all_clients')"
                                 icon-pack="lifetec" icon="humans"
                                 label="Users" href="/users" :active="isActiveElement('/users')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:helsinki')"
                                 icon-pack="lifetec" icon="humans"
                                 label="Helsinki Users" href="/helsinki_users" :active="isActiveElement('/helsinki_users')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:questioner')"
                                 icon-pack="lifetec" icon="questionier"
                                 label="Questionnaire" href="/questionary"
                                 :active="isActiveElement('/questionary')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:question')"
                                 icon-pack="lifetec" icon="questions"
                                 label="Questions" href="/questions"
                                 :active="isActiveElement('/questions')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:stages')"
                                 icon-pack="lifetec" icon="stages"
                                 label="Stages" href="/stages"
                                 :active="isActiveElement('/stages')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:nutrition_instructions')"
                                 icon-pack="lifetec" icon="heart_fork"
                                 label="Nutrition Instructions" href="/nutritions"
                                 :active="isActiveElement('/nutritions')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('read:tech_support')"
                                 icon-pack="lifetec" icon="support"
                                 label="Support" href="/support"
                                 :active="isActiveElement('/support')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('write:chat_templates')"
                                 icon="message"
                                 label="Chat Templates" href="/chat_templates"
                                 :active="isActiveElement('/chat_templates')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('write:action_triggers')"
                                 icon="apps"
                                 label="Action Triggers" href="/states"
                                 :active="isActiveElement('/states')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('write:medications')"
                                 icon-pack="lifetec" icon="pills"
                                 label="Medicines" href="/medicine"
                                 :active="isActiveElement('/medicine')"></b-menu-item>
                    <b-menu-item class="menu-item"
                                 v-if="hasPermission('write:supplements')"
                                 icon="pill"
                                 label="Supplements" href="/supplements"
                                 :active="isActiveElement('/supplements')"></b-menu-item>
                </b-menu-list>
                <b-menu-list label="Actions">
                    <b-menu-item class="menu-item" size="is-medium"
                                 v-if="isAuthenticated" icon-pack="lifetec" icon="logout" label="Logout"
                                 @click="logout"></b-menu-item>
                    <b-menu-item class="menu-item" size="is-medium"
                                 v-if="!isAuthenticated" icon-pack="lifetec" icon="login" label="Log In"
                                 @click="login"></b-menu-item>
                </b-menu-list>
                <div class="appVersion">Version: {{getAppVersion}}</div>
            </b-menu>
        </div>
    </b-sidebar>
</template>

<script>

    import {mapGetters} from 'vuex';
    import {AUTH_LOGOUT, AUTH_REQUEST} from "../store/constants";

    const customIconConfig = {
        customIconPacks: {
            'fas': {
                sizes: {
                    'default': null,
                    'is-small': 'fa-xs',
                    'is-medium': 'fa-lg',
                    'is-large': 'fa-2x'
                }
            },
            'lifetec': {
                sizes: {
                    'default': 'is-size-2',
                    'is-small': 'is-size-5',
                    'is-medium': 'is-size-3',
                    'is-large': 'is-size-1'
                },
                iconPrefix: 'lifetec-',
                internalIcons: {
                    'calendar': 'calendar',
                    'chat_send_btn': 'chat_send_btn',
                    'chat_send_file': 'chat_send_file',
                    'heart_fork': 'heart_fork',
                    'humans': 'humans',
                    'login': 'login',
                    'logout': 'logout',
                    'menu': 'menu',
                    'pawprint': 'pawprint',
                    'pills': 'pills',
                    'questionier': 'questionier',
                    'questions': 'questions',
                    'support': 'support',
                    'stages': 'stages'
                }
            }
        }
    };

    export default {
        name: "MainMenuSidebar",
        props:{
            isWide: Boolean,
            isOpen: Boolean,
        },
        data() {
            return {
                expandOnHover: true,
                mobile: "fullscreen",
                position: "fixed",
                fullHeight: true,
                sidebarType: "is-white is-large",
                canCancel: false,
            };
        },
        created() {
            this.$buefy.config.setOptions(customIconConfig)
        },
        computed: {
            ...mapGetters(['isAuthenticated', 'hasPermission', 'authStatus', 'isSocketConnected', 'getAppVersion']),
            reduce() {
                return this.isWide;
            },
            overlay(){
                return !this.isWide&&this.isOpen;
            },
        },
        methods: {
            isActiveElement(path) {
                return path === this.$route.path;
            },
            login: async function () {
                await this.$store.dispatch(AUTH_REQUEST);
                await this.$router.push('/')
            },
            logout: async function () {
                const loc = {returnTo: window.location.protocol + "//" + window.location.host};
                await this.$store.dispatch(AUTH_LOGOUT, loc);
                await this.$router.push('/')
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import "./../../public/css/lifetec.css";

    .menu-item {
        height: 30px;
        overflow: hidden;
    }

    .p-1 {
        padding: 1em;
    }

    .sidebar {
        margin-right: 15px;
    }

    .is-custom-mobile {
        text-align: left;
    }

    .appVersion {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1em;
        text-align: center;
        font-size: 0.75em;
        font-weight: bold;
        color: #7a7a7a;
    }
</style>
