import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import createAuth0Client from "@auth0/auth0-spa-js";

const  BASE_URL = 'http://52.168.10.213:8080/';
const ACCESS_TOKEN_KEY = 'access-token';

const saveToken = (token) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const getToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY)
};

const deleteToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
};

const hasToken = () => {
    return localStorage.hasOwnProperty(ACCESS_TOKEN_KEY)
};

export const ApiService = {
    /**
     * Initializes http client to vue
     */
    init() {
        Vue.use(VueAxios, axios.create());
        Vue.axios.defaults.baseURL = BASE_URL;
    },

    setDefaultHeader(name, value) {
        Vue.axios.defaults.headers.common[name] = value
    },

    setAuthToken(token) {
        ApiService.setDefaultHeader("Authorization", `Bearer ${token}`);
    },

    async getResourceList(resource) {
        try {
            const resp = await Vue.axios.get(resource);
            return resp.data;
        }catch (e) {
            window.alert(e.response.status);
        }
    }

};

export const Auth0Service = {
    client: null,
    async init(domain, client_id, audience) {
        Auth0Service.client = await createAuth0Client({
            domain: domain,
            client_id: client_id,
            redirect_uri: window.location.protocol + "//" + window.location.host + "/",
            audience: audience
        });

        Vue.$log.info(Auth0Service.client);

        if (hasToken()) {
            ApiService.setAuthToken(getToken())
        }
    },

    async login() {
        await Auth0Service.client.loginWithPopup();
        const token = await Auth0Service.client.getTokenSilently();
        saveToken(token);
        ApiService.setAuthToken(token);
        return await Auth0Service.client.getUser()
    },

    async logout() {
        await Auth0Service.client.logout();
        deleteToken()
    },

    isAuthenticated() {
        return hasToken();
    }
};

export const QuestionsService = {
    async getQuestions(){
        return await ApiService.getResourceList('questions')
    }
};

export const StagesService = {
    async getLevels(){
        return await ApiService.getResourceList('levels')
    }
};

let axiosInstanse = null;

export const getAxios = function() {
    if (axiosInstanse == null) {
        axiosInstanse = axios.create()
    }
    return axiosInstanse
}