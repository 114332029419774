import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueNativeSock from 'vue-native-websocket'
import {getAxios} from './api'
import * as firebase from "firebase/app"
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyBzYXQzNBlEfcW-ik8vOUkdU-u3WDQdKMY",
    authDomain: "lifetec-de286.firebaseapp.com",
    databaseURL: "https://lifetec-de286.firebaseio.com",
    projectId: "lifetec-de286",
    storageBucket: "lifetec-de286.appspot.com",
    messagingSenderId: "617757683044",
    appId: "1:617757683044:web:2a7bcc10995fcb3d525e71",
    measurementId: "G-Y132LT9TCW"
};
let firebaseToken;

firebase.initializeApp(firebaseConfig);
(async function init() {
    firebaseToken = await firebase.messaging().getToken()
    window.console.log(firebaseToken)
})().then(()=>{});

// firebase.messaging().onMessage((payload) => {
//     window.console.log(payload)
// });

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
Vue.use(Buefy);

import VueChatScroll from 'vue-chat-scroll';
Vue.use(VueChatScroll);

import VueDraggable from 'vue-draggable';
Vue.use(VueDraggable);

const axios = getAxios();

const token = localStorage.getItem('user-token');
const user_id = localStorage.getItem('user-id');
store.commit('AUTH_SET_ID',user_id);
store.commit('AUTH_SUCCESS',token);
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    store.dispatch('AUTH_GET_ME');
}

import VueLogger from 'vuejs-logger';

const isProduction = process.env.NODE_ENV === 'production';
const options = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
};

Vue.use(VueLogger, options);

Vue.config.productionTip = false;
//const ws = 'wss://messaging.bhvtec.com/ws';
const ws = process.env.VUE_APP_WS;

Vue.use(VueNativeSock, ws, {
    store: store,
    format: 'json',
    reconnection: true,
    passToStoreHandler: function (eventName, event) {
        if (!eventName.startsWith('SOCKET_')) {
            return
        }
        let method = 'dispatch';
        let target = eventName.toUpperCase();
        let msg = event;
        if (this.format === 'json' && event.data) {
            msg = JSON.parse(event.data);
            if (msg.mutation) {
                target = [msg.namespace || '', msg.mutation].filter((e) => !!e).join('/')
            } else if (msg.action) {
                method = 'dispatch';
                target = [msg.namespace || '', msg.action].filter((e) => !!e).join('/')
            }
        }
        this.store[method](target, msg)
    }});

window.onbeforeunload = () => {
    if (sessionStorage.getItem('fcm') === 'connected') {
        localStorage.removeItem('fcm');
    }
}

window.onstorage = () => {
        setTimeout(() => {
            if (localStorage.getItem('fcm') !== 'connected') {
                Vue.prototype.$socket.send((JSON.stringify({
                    "method": "subscribe_leader",
                    "data":
                        {"token": localStorage.getItem('user-token'), "registration_token": firebaseToken}
                })))
                localStorage.setItem('fcm', 'connected')
                sessionStorage.setItem('fcm', 'connected')
                Vue.prototype.$socket.send(JSON.stringify({ "method":"ping"}))
            }
        }, Math.random() * 1000)
};

const appVersion = require('../package.json').version;
store.commit('SET_VERSION', appVersion);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
