import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import user from './modules/user'
import questions from "./modules/questions";
import questionary from "./modules/questionary";
import user_questionnaire from "./modules/user_questionnaire";
import socket from "./modules/socket";
import chat from "./modules/chat";
import chats from "./modules/chats";
import translation from "./modules/translation";
import stages from "./modules/stages";
import medicalExams from "./modules/medicalExams";
import nutritionInstructions from "./modules/nutritionInstructions";
import clientNutritionInsrtuctions from './modules/clientNutritionInsrtuctions'
import chatTemplates from "./modules/chatTemplates";
import files from './modules/files'
import statesMachine from "./modules/statesMachine";
import clientStages from "./modules/clientStages";
import medicineItem from "./modules/medicineItem";
import supplementsItem from "./modules/supplementsItem";
import clientSupplementsInstruction from "./modules/clientSupplementsInstruction";
import clientMedicineInstructions from "./modules/clientMedicineInstructions";
import config from "./modules/config";


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    user,
    questions,
    questionary,
    socket,
    chat,
    chats,
    chatTemplates,
    translation,
    stages,
    clientStages,
    medicalExams,
    nutritionInstructions,
    clientNutritionInsrtuctions,
    files,
    statesMachine,
    user_questionnaire,
    medicineItem,
    supplementsItem,
    clientSupplementsInstruction,
    clientMedicineInstructions,
    config,
  },
  strict: debug,
})
