import Vue from 'vue';
import * as firebase from 'firebase/app';
import 'firebase/messaging'
import {ToastProgrammatic as Toast} from 'buefy'

const state = {
    socketConnected: false,
    socketMessage: '',
    socketError: false,
    socketTimestamp: 0,
    socketReconnectError: false,
};

const getters = {
    getSocketMessage: state => state.socketMessage,
    isSocketConnected: state => state.socketConnected,
    isSocketError: state => state.socketError,
}

const actions = {
    async SOCKET_ONOPEN({store}, event) {
        Vue.prototype.$socket = event.currentTarget;
        this._vm.$log.info(store);
        const token = localStorage.getItem('user-token');
        const registration_token = await firebase.messaging().getToken();
        if (localStorage.getItem('fcm') !== 'connected') {
            Vue.prototype.$socket.send(JSON.stringify({
                "method": "subscribe_leader",
                "data":
                    {"token": token, "registration_token": registration_token}
            }))
            localStorage.setItem('fcm', 'connected')
            sessionStorage.setItem('fcm', 'connected')
            this._vm.$log.info(sessionStorage.getItem('fcm connected'));
        }
        //commit('SOCKET_ONCONNECT');
    },
    SOCKET_TEST({getters, commit}) {
        if (getters.isSocketError) {
            commit('SOCKET_ONCLOSE');
            location.reload(true);
        } else
            commit('SOCKET_ONERROR');
            Vue.prototype.$socket.send(JSON.stringify({ "method":"ping"}))
    },
    SOCKET_ONCLOSE({commit}) {
        commit('SOCKET_ONCLOSE');
        Vue.prototype.$log.error('SOCKET_ONCLOSE');
        if (localStorage.getItem('fcm') === 'connected') {
            localStorage.removeItem('fcm')
            }
    },
    SOCKET_ONERROR({commit, state}, event) {
        Vue.prototype.$log.error(state, event)
        commit('SOCKER_ONERROR');
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE({commit}, message) {
        this._vm.$log.info(message);
        if (typeof message.type !== 'undefined') {
            if (message.type === 'event') {
                if (message.event === 'chat_message_sent') {
                    const sound = new Audio(require("../../assets/audio/ping.wav"));
                    sound.play();
                    Toast.open({
                        duration: 2000,
                        message: 'You got a chat message',
                        type: 'is-white',
                        position: "is-bottom-right",
                    });
                    commit('CHAT_ONMESSAGE', message.data);
                    commit('USER_ONMESSAGE', message.data);
                    commit('CHATS_ONMESSAGE', message.data);
                }
                if (message.event === 'chat_message_read') {
                    commit('CHAT_ONREAD', message.data);
                    commit('USER_ONREAD', message.data);
                }
                if (message.event === 'chat_messages_deleted') {
                    commit('CHAT_ONDELETE', message.data);
                }
                if (message.event === 'connected') {
                    commit('SOCKET_ONCONNECT');
                }

            }
            commit('SOCKET_NOERROR');
        }

    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        Vue.prototype.$log.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
        Vue.prototype.$log.error('SOCKET_RECONNECT_ERROR');
        state.socketReconnectError = true;
    },
};

const mutations = {
    ['SOCKET_ONCLOSE'](state) {
        state.socketConnected = false;
    },
    ['SOCKET_SET_MESSAGE'](state, message) {
        state.message = message;
    },
    ['SOCKET_ONCONNECT'] (state) {
        state.socketConnected = true;
    },
    ['SOCKET_ONERROR'](state) {
        state.socketError = true;
    },
    ['SOCKET_NOERROR'](state) {
        state.socketError = false;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}