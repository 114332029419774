//============ AUTH =======================
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_GET_USER = 'AUTH_GET_USER';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_SET_ID = 'AUTH_SET_ID';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_ERROR = 'AUTH_ERROR';


//============ USER =======================
export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const USER_CREATE = 'USER_CREATE';
export const USER_GET = 'USER_GET';
export const USER_GET_GLUCOSE_CALENDAR = 'USER_GET_GLUCOSE_CALENDAR';
export const USER_GET_QUESTIONERS = 'USER_GET_QUESTIONERS';
export const USER_ONMESSAGE = 'USER_ONMESSAGE';
export const USER_ONREAD = 'USER_ONREAD';
export const USER_SET_LAST_UPDATE = 'USER_SET_LAST_UPDATE';


//============ QUESTIONS ==================
export const QUESTIONS_GET = 'QUESTIONS_GET';
export const QUESTIONS_CREATE = 'QUESTIONS_CREATE';
export const QUESTIONS_EDIT = 'QUESTIONS_EDIT';
export const QUESTIONS_DELETE = 'QUESTIONS_DELETE';
export const QUESTIONS_CATEGORY_GET = 'QUESTIONS_CATEGORY_GET';
export const QUESTIONS_CATEGORY_CREATE = 'QUESTIONS_CATEGORY_CREATE';
export const QUESTIONS_CATEGORY_EDIT = 'QUESTIONS_CATEGORY_EDIT';
export const QUESTIONS_CATEGORY_DELETE = 'QUESTIONS_CATEGORY_DELETE';
export const QUESTIONS_ERROR = 'QUESTIONS_ERROR';
export const QUESTIONS_SUCCESS = 'QUESTIONS_SUCCESS';


//============ QUESTIONARY =============
export const QUESTIONARY_GET = 'QUESTIONARY_GET';
export const QUESTIONARY_START = 'QUESTIONARY_START';
export const QUESTIONARY_CREATE = 'QUESTIONARY_CREATE';
export const QUESTIONARY_EDIT = 'QUESTIONARY_EDIT';
export const QUESTIONARY_DELETE = 'QUESTIONARY_DELETE';
export const QUESTIONARY_USE_AT_START = 'QUESTIONARY_UAE_AT_START';
export const QUESTIONARY_USE_AT_FINISH = 'QUESTIONARY_UAE_AT_FINISH';
export const QUESTIONARY_USE_DAILY = 'QUESTIONARY_UAE_DAILY';
export const QUESTIONARY_ERROR = 'QUESTIONARY_ERROR';
export const QUESTIONARY_SUCCESS = 'QUESTIONARY_SUCCESS';
export const DAILY_QUESTIONARY_DELETE = 'DAILY_QUESTIONARY_DELETE'


//============ TRANSLATIONS ==============
export const TRANSLATIONS_GET = 'TRANSLATIONS_GET';
export const TRANSLATION_GET = 'TRANSLATION_GET';
export const TRANSLATION_CREATE = 'TRANSLATION_CREATE';
export const TRANSLATION_EDIT = 'TRANSLATION_EDIT';

//============ CHAT =====================
export const CHAT_CONNECT = 'CHAT_CONNECT';
export const CHAT_CLEAR = 'CHAT_CLEAR';
export const CHAT_GET = 'CHAT_GET';
export const CHAT_POST = 'CHAT_POST';
export const CHAT_POST_TEMPLATE = 'CHAT_POST_TEMPLATE';
export const CHAT_READ = 'CHAT_READ';
export const CHAT_READ_ALL = 'CHAT_READ_ALL';
export const CHAT_DELETE = 'CHAT_DELETE';
export const CHAT_ON_DELETE_SEND = 'CHAT_ON_DELETE_SEND';


export const CHAT_ONCONNECT = 'CHAT_ONCONNECT';
export const CHAT_GOTMESSAGES = 'CHAT_GOTMESSAGES';
export const CHAT_ONMESSAGE = 'CHAT_ONMESSAGE';
export const CHAT_ONREAD = 'CHAT_ONREAD';
export const CHAT_ONDELETE = 'CHAT_ONDELETE';

//============ STAGES =====================

export const LEVELS_GET = 'LEVELS_GET';
export const LEVELS_GET_SUCCESS = 'LEVELS_GET_SUCCESS';
export const LEVELS_GET_ERROR = 'LEVELS_GET_ERROR';

export const STAGES_GET = 'STAGES_GET';
export const STAGES_GET_SUCCESS = 'STAGES_GET_SUCCESS';
export const STAGES_ERROR = 'STAGES_ERROR';
