import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    client_supplements_status: String,
    client_supplements: [],
};

const getters = {
    getClientSupplementsInstructions: state => state.client_supplements,
    getClientSupplementsInstruction: state => supplementsId => {
        return state.client_supplements.find(el => el.item_id === supplementsId)
    }
};

const actions = {
    async ['CLIENT_SUPPLEMENTS_INSTRUCTION_GET']({commit}, user_id) {
        commit('CLIENT_SUPPLEMENTS_LOADING');
        try {
            const str = 'clients/' + user_id + '/supplements';
            const resp = await axios.get(str);
            commit('CLIENT_SUPPLEMENTS_INSTRUCTION_GET_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('CLIENT_SUPPLEMENTS_ERROR');
            return e.response
        }
    },
    async ['CLIENT_SUPPLEMENTS_INSTRUCTION_UPDATE']({commit}, {user, instruction}) {
        commit('CLIENT_SUPPLEMENTS_LOADING');
        try {
            const query = 'clients/'+ user + '/supplements/'+ instruction.item_id;
            const assigned = instruction.assigned;
            if (assigned === 0) await axios.delete(query);
            if (assigned === 1) await axios.post(query);
            commit('CLIENT_SUPPLEMENTS_INSTRUCTION_UPDATE_SUCCESS', instruction);
        }catch (e) {
            commit('CLIENT_SUPPLEMENTS_ERROR');
            return e.response
        }
    },
};

const mutations = {
    ['CLIENT_SUPPLEMENTS_LOADING'](state){
        state.client_supplements_status = 'loading';
    },
    ['CLIENT_SUPPLEMENTS_ERROR'](state) {
        state.client_supplements_status = 'error';
    },
    ['CLIENT_SUPPLEMENTS_INSTRUCTION_GET_SUCCESS'](state, data) {
        state.client_supplements_status = 'success';
        state.client_supplements = data;
    },
    ['CLIENT_SUPPLEMENTS_INSTRUCTION_UPDATE_SUCCESS'](state, data) {
        state.client_supplements_status='success';
        let nutrition = [...state.client_supplements.filter(el=> el.item_id !== data.item_id)];
        state.client_supplements = [];
        nutrition.push(data);
        state.client_supplements = nutrition;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};