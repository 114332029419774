
const state = {
    appVersion: '0.0.0',
};

const getters = {
    getAppVersion: state => state.appVersion,
};

const mutations = {
    ["SET_VERSION"](state, version) {
        state.appVersion = version;
    },
};

export default {
    state,
    getters,
    mutations
}
