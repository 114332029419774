import {getAxios} from '../../api'

const axios = getAxios();

const state = {
    chats_status: 'ok',
    chats_data_expire: 0,
    chats: [],

};

const getters = {
    getChatsStatus: state => state.chats_status,
    getChatsExpired: state => new Date().getTime() > state.chats_data_expire,
    getChats: state => state.chats,
    getChatsUser: (state) => (chat_id, leader_id) => {
        return state.chats.find(chat => chat.id === chat_id).users.find(user => user.id !== leader_id)
    }
};

const actions = {
    async ['CHATS_GET']({commit}) {
        commit('CHATS_STATUS_SET', 'loading');
        try {
            const resp = await axios.get('chat/rooms?user_info');
            commit('CHATS_SET', resp.data)
        } catch (e) {
            this._vm.$log.error(e);
            commit('CHATS_STATUS_SET', 'error');
        }
    }

};

const mutations = {
    ['CHATS_STATUS_SET'](state, status) {
        state.status = status;
    },
    ['CHATS_SET'](state, data) {
        state.chats = data;
        state.chats_status = 'ok';
        const date = new Date();
        state.chats_data_expire = date.getTime() + 1000 * 60 * 15; // +15min expiration
    },
    ['CHATS_ONMESSAGE'](state, message) {
        for (let index in state.chats) {
            if (state.chats[index].id == message.room_id) {
                state.chats[index].unread += 1;
            }
        }
    },
    ['CHATS_ONREAD'](state, messages) {
        state.chats_data_expire = 0;
        if(state.chats.length >0 ) {
            if (state.chats.find(el => el.id === messages.room_id) !== undefined) {
                state.chats.find(el => el.id === messages.room_id).unread = 0;
            }
        }
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}