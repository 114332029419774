import {QUESTIONS_ERROR, QUESTIONS_GET, QUESTIONS_SUCCESS, QUESTIONS_CREATE, QUESTIONS_DELETE, QUESTIONS_EDIT,
    QUESTIONS_CATEGORY_GET, QUESTIONS_CATEGORY_CREATE, QUESTIONS_CATEGORY_DELETE, QUESTIONS_CATEGORY_EDIT} from "../constants";

import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    status:''
};

const getters = {};

const actions = {
    async [QUESTIONS_GET]({commit}) {
        commit(QUESTIONS_GET);
        try {
            const resp = await axios.get('questioner/questions');
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit(QUESTIONS_ERROR);
            return e.response
        }
    },
    async [QUESTIONS_CREATE]({commit}, question) {
        commit(QUESTIONS_CREATE);
        try {
            const resp = await axios.post('questioner/questions', question);
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp
        }catch (e) {
            this._vm.$log.error(e);
            commit(QUESTIONS_ERROR);
            return e.response;
        }
    },
    async [QUESTIONS_EDIT]({commit}, question) {
        commit(QUESTIONS_EDIT);
        try {
            const resp = await axios.post('questioner/questions/'+ question.id , question);
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp
        }catch (e) {
            this._vm.$log.error(e);
            commit(QUESTIONS_ERROR);
            return e.response;
        }
    },
    async [QUESTIONS_DELETE]({commit}, id) {
        commit(QUESTIONS_DELETE);
        try {
            const resp = await axios.delete('questioner/questions/'+id);
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONS_ERROR);
            return e.response;
        }
    },
    async [QUESTIONS_CATEGORY_GET]({commit}){
        commit(QUESTIONS_GET);
        try {
            const resp = await axios.get('questioner/questions_category');
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp;
        } catch (e) {
            this._vm.$log.error(e);
            commit(QUESTIONS_ERROR);
            return e.response
        }
    },
    async [QUESTIONS_CATEGORY_CREATE]({commit}, category) {
        commit(QUESTIONS_CREATE);
        try {
            const resp = await axios.post('questioner/questions_category', category);
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp
        }catch (e) {
            this._vm.$log.error(e);
            commit(QUESTIONS_ERROR);
            return e.response;
        }
    },
    async [QUESTIONS_CATEGORY_EDIT]({commit}, category) {
        commit(QUESTIONS_EDIT);
        try {
            const resp = await axios.post('questioner/questions_category/'+ category.id , category);
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp
        }catch (e) {
            this._vm.$log.error(e);
            commit(QUESTIONS_ERROR);
            return e.response;
        }
    },
    async [QUESTIONS_CATEGORY_DELETE]({commit}, id) {
        commit(QUESTIONS_DELETE);
        try {
            const resp = await axios.delete('questioner/questions_category/'+id);
            //this._vm.$log.info(resp);
            commit(QUESTIONS_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONS_ERROR);
            return e.response;
        }
    },
 };

const mutations = {
    [QUESTIONS_GET]: (state) => {
        state.state = 'getting';
    },
    [QUESTIONS_SUCCESS]: (state, resp) => {
        state.status = 'success';
        state.profile = resp;
    },
    [QUESTIONS_ERROR]: (state) => {
        state.status = 'error'
    },
    [QUESTIONS_CREATE]: (state) => {
        state.status = 'creation'
    },
    [QUESTIONS_EDIT]: (state) => {
        state.status = 'edit'
    },
    [QUESTIONS_DELETE]: (state) => {
        state.status = 'delete'
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}



