import {getAxios} from '../../api'

const axios = getAxios();

const state = {
    user_questionnaires_status: 'success',
    user_questionnaires_user: 'user',
    user_questionnaires: [],
    user_questionnaires_questions: [],
    user_questionnaires_compare: [],
    user_questionnaires_comparison: [],
}

const getters = {
    getUserQuestionnaires: state => {
        return state.user_questionnaires
    },
    isUserQuestionnaire: state => {
        return user_id => {
            return state.user_questionnaires_user === user_id;
        }
    },
    getUserQuestionnairesStatus: state => {
        return state.user_questionnaires_status;
    },
    getUserQuestionnairesComparisonQuestions: state => {
        return state.user_questionnaires_questions;
    },
    getUserQuestionnairesCompare: state => {
        return state.user_questionnaires_compare;
    },
    getUserQuestionnairesComparison: state => {
        return state.user_questionnaires_comparison;
    }
}

const actions = {
    async ['USER_GET_QUESTIONERS'](context, user_id) {
        context.commit('USER_GET_QUESTIONNAIRE')
        try {
            const str = 'clients/' + user_id + '/questioners';
            const resp = await axios.get(str);
            this._vm.$log.debug(resp);
            context.commit('USER_GET_QUESTIONNAIRE_SUCCESS', {data: resp.data, user_id: user_id});
            return resp.data;
        } catch (e) {
            this._vm.$log.error(e.response);
            context.commit('USER_GET_QUESTIONNAIRE_ERROR');
            return e.response;
        }
    },
    async ['USER_SET_QUESTIONERS_COMPARE'](context, payload) {
        context.commit('USER_SET_QUESTIONERS_COMPARE', payload)
    }
}

const mutations = {
    ['USER_GET_QUESTIONNAIRE'](state) {
        state.user_questionnaires_status = 'loading';
    },
    ['USER_GET_QUESTIONNAIRE_ERROR'](state) {
        state.user_questionnaires_status = 'error';
    },
    ['USER_SET_QUESTIONERS_COMPARE'](state, payload) {
        state.user_questionnaires_compare = payload;
        let user_questionnaires_questions = [];
        let index = 0;
        for (let questionnaire of state.user_questionnaires) {
            if (state.user_questionnaires_compare[index] === true) {
                for (let question of questionnaire.questions) {
                    let newQuestion = true;
                    for (let curr_questions of user_questionnaires_questions) {
                        if (curr_questions.id === question.id) newQuestion = false;
                    }
                    if (newQuestion) user_questionnaires_questions.push({
                        id: question.id,
                        title: question.title,
                        label: 'question'
                    });
                }
            }
            index++;
        }
        state.user_questionnaires_questions = user_questionnaires_questions;
        let user_questionnaires_comparison = [];
        for (let question of state.user_questionnaires_questions) {
            let question_row = [];
            let key = 0;
            index = 0;
            for (const questionnaire of state.user_questionnaires) {
                if (state.user_questionnaires_compare[index] === true) {
                    let current_question = questionnaire.questions.find(el => el.id === question.id)
                    let tmp_element;
                    if (current_question !== undefined)
                        tmp_element = current_question;
                    else
                        tmp_element = question;
                    let current_answer = questionnaire.answers.find(el => el.question === question.id)
                    if (current_answer !== undefined)
                        tmp_element.answer = current_answer;
                    else if (tmp_element.hasOwnProperty('answer')) {
                        delete tmp_element.answer;
                    }
                    key = key + 1;
                    tmp_element.label = questionnaire.finished_at;//title + ' [' + questionnaire.type + ']';
                    tmp_element.type = questionnaire.type;
                    question_row.push(tmp_element);
                }
                index++;
            }
            user_questionnaires_comparison.push(question_row);
        }
        state.user_questionnaires_comparison = user_questionnaires_comparison;
    },
    ['USER_GET_QUESTIONNAIRE_SUCCESS'](state, payload) {
        state.user_questionnaires = payload.data.sort((a, b) => new Date(a.started_at) - new Date(b.started_at));
        state.user_questionnaires_user = payload.user_id;

        let user_questionnaires_compare = [];
        for (let questionnaire of state.user_questionnaires) {
            if ((questionnaire.type === 'start' || questionnaire.type === 'finish') && (questionnaire.finished))
                user_questionnaires_compare.push(true)
            else
                user_questionnaires_compare.push(false)
        }
        this.dispatch('USER_SET_QUESTIONERS_COMPARE', user_questionnaires_compare)
        state.user_questionnaires_status = 'success'
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}