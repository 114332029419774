import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    status: String,
    nutrition: [],
};

const getters = {
    getClientNutritionInstructions: state => state.nutrition,
    getClientNutritionInstruction: state => nutritionId => {
        return state.nutrition.find(el => el.item_id === nutritionId)
    }
};

const actions = {
    async ['CLIENT_NUTRITION_INSTRUCTION_GET']({commit}, user_id) {
        commit('CLIENT_NUTRITION_LOADING');
        try {
            const str = 'clients/' + user_id + '/nutrition';
            const resp = await axios.get(str);
            commit('CLIENT_NUTRITION_INSTRUCTION_GET_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('CLIENT_NUTRITION_ERROR');
            return e.response
        }
    },
    async ['CLIENT_NUTRITION_INSTRUCTION_UPDATE']({commit}, {user, instruction}) {
        commit('CLIENT_NUTRITION_LOADING');
        try {
            const query = 'clients/'+ user + '/nutrition/'+ instruction.item_id;
            const role = instruction.role;
            if (role === 0) await axios.delete(query);
            if (role === 1) await axios.post(query);
            if (role === 2) await axios.put(query);
            commit('CLIENT_NUTRITION_INSTRUCTION_UPDATE_SUCCESS', instruction);
        }catch (e) {
            commit('CLIENT_NUTRITION_ERROR');
            return e.response
        }
    },
};

const mutations = {
    ['CLIENT_NUTRITION_LOADING'](state){
        state.status = 'loading';
    },
    ['CLIENT_NUTRITION_ERROR'](state) {
        state.status = 'error';
    },
    ['CLIENT_NUTRITION_INSTRUCTION_GET_SUCCESS'](state, data) {
        state.status = 'success';
        state.nutrition = data;
    },
    ['CLIENT_NUTRITION_INSTRUCTION_UPDATE_SUCCESS'](state, data) {
        state.status='success';
        let nutrition = [...state.nutrition.filter(el=> el.item_id !== data.item_id)];
        state.nutrition = [];
        nutrition.push(data);
        state.nutrition = nutrition;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
