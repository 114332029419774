import {
    CHAT_CONNECT,
    CHAT_CLEAR,
    CHAT_GET,
    CHAT_GOTMESSAGES,
    CHAT_ONCONNECT,
    CHAT_ONMESSAGE,
    CHAT_ONREAD,
    CHAT_ONDELETE,
    CHAT_POST,
    CHAT_POST_TEMPLATE,
    CHAT_READ,
    CHAT_READ_ALL,
    CHAT_DELETE,
    CHAT_ON_DELETE_SEND,
    USER_ONREAD,
} from "../constants";

import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    room_id: 0,
    chat_messages: [],
    chat_status: 'disconnected',
    chat_message_status: 'success',
    lastread: true
};

const getters = {
    getMessages(state) {
        return state.chat_messages;
    },
    getLastRead(state) {
        return state.lastread;
    },
    getRoomMessages(state) {
        return room_id => state.chat_messages.filter(item => {
            return item.room_id == room_id
        })
    },
    getChatStatus(state) {
        return state.chat_status;
    }
};

const actions = {
    async [CHAT_CONNECT]({commit}, user_id) {
        try {
            await axios.post('chat/rooms', user_id);
            commit(CHAT_ONCONNECT)
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_CLEAR]({commit}) {
        try {
            commit(CHAT_CLEAR);
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_GET]({commit,state}, {room_id, top}) {
        try {
            var skip = state.chat_messages.length;
            const resp = await axios.get('chat/rooms/' + room_id+'?top='+top+'&skip='+ skip);
            commit(CHAT_GOTMESSAGES, resp.data)
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_POST]({commit}, {room_id, message}) {
        try {
            const resp = await axios.post('chat/rooms/' + room_id, message);
            commit('CHAT_POSTED_TEMPLATE', resp.data);
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_POST_TEMPLATE]({commit}, {room_id, template_id}) {
        try {
            const resp = await axios.post('chat/rooms/' + room_id + '/'+ template_id);
            commit('CHAT_POSTED_TEMPLATE', resp.data);
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_READ]({commit}, {room_id, messages}) {
        try {
            await axios.put('chat/rooms/' + room_id + '/messages', messages);
            commit(USER_ONREAD);
            commit('CHATS_ONREAD', messages);
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_READ_ALL]({commit}, room_id) {
        try {
            await axios.post('chat/rooms/' + room_id + '/messages');
            commit(USER_ONREAD);
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
    async [CHAT_DELETE]({commit}, {room_id, messages}) {
        try {
            await axios.delete('chat/rooms/' + room_id, {data: {messages}});
            commit(CHAT_ON_DELETE_SEND, messages);
        } catch (e) {
            this._vm.$log.error(e);
        }
    },
};

const mutations = {
    [CHAT_GOTMESSAGES](state, messages) {
        messages.forEach(message => state.chat_messages.splice(0, 0, message));
        state.lastread = messages.length;
    },
    [CHAT_CLEAR](state) {
        state.chat_messages = [];
        state.lastread = 1;
    },
    [CHAT_ONMESSAGE](state, message) {
        state.chat_messages.push(message);
    },
    ['CHAT_POSTED_TEMPLATE'](state, message) {
        state.chat_message_status = 'success';
        state.chat_messages.push(message);
    },
    [CHAT_ONREAD](state, resp) {
        this._vm.$log.info(resp);
    },
    [CHAT_ON_DELETE_SEND](state, messages) {
        state.chat_messages = state.chat_messages.filter(item => {
            return !messages.includes(item.id)
        });
    },
    [CHAT_ONDELETE](state, resp) {
        let messages_for_delete = resp.message_ids;
        state.chat_messages = state.chat_messages.filter(item => {
            return !messages_for_delete.includes(item.id)
        });
    },
    [CHAT_ONCONNECT](state) {
        state.chat_status = 'connected'
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
