import {getAxios} from '../../api'
const axios = getAxios();

const state = {
    filesStatus: 'success',
};

const getters = {
    getFilesStatus: state => state.filesStatus,
};

const actions = {
    async ["FILE_UPLOAD"]({commit}, payload){
        commit("FILE_LOADING");
        try {
            const path = '/files/upload';
            const resp = await axios.put(path, payload.data, {
                headers: {
                    'Content-Type': payload.type,
                    'Content-Disposition': 'attachment; filename=upload.jpg'
                }
            });
            commit("FILE_SUCCESS");
            return resp;
        }catch (e) {
            commit("FILE_ERROR");
            return e.response
        }
    }

};

const mutations = {
    ["FILE_LOADING"](state) {
        state.filesStatus = 'loading'
    },
    ["FILE_ERROR"](state) {
        state.filesStatus = 'error'
    },
    ["FILE_SUCCESS"](state) {
        state.filesStatus = 'success'
    },
};



export default {
    state,
    getters,
    actions,
    mutations
}