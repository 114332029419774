import {LEVELS_GET, LEVELS_GET_SUCCESS, LEVELS_GET_ERROR} from "../constants";

import validator from 'validator';
import moment from "moment";

import {getAxios} from '../../api'
const axios = getAxios();


const validateLevel = fields => {
    let errors = {};
    for (let name in fields) {
        if (name === "name") {
            const value = fields[name];
            if (validator.isEmpty(value) || value.length < 2) {
                errors[name] = true
            }
        }
        if (name === "description") {
            const value = fields[name];
            if (validator.isEmpty(value) || value.length < 2) {
                errors[name] = true
            }
        }
    }
    return errors;
};

const validateStage = fields => {
    let errors = {};
    for (let name in fields) {
        const value = fields[name];
        if (name === "title") {
            if (validator.isEmpty(value) || value.length < 2) {
                errors[name] = true
            }
        }
        if (name === "duration") {
            if (!(value > 0)) {
                errors[name] = true
            }
        }
        if (name === "goal") {
            if (!(value > 0)) {
                errors[name] = true
            }
        }
        if (name === "action_types") {
            let actionsError = false;
            if (value.length == 0) actionsError = true;
            for (let action of value) {
                if (action !== undefined) {
                    if (action.hasOwnProperty('errors')) {
                        if (Object.keys(action.errors).length > 0) actionsError = true;
                    }
                }
            }
            if (actionsError) errors['actions_type'] = true;
        }
    }
    return errors;
};


const validateAction = fields => {
    let errors = {};
    if (!(fields['reward'] >= 0)) errors['reward'] = true;
    const start = new Date(moment(fields['start_time'], 'HH:mm'));
    const end = new Date(moment(fields['end_time'], 'HH:mm'));
    if (start >= end) errors['time'] = true;
    return errors;
};

const state = {
    stages_status: 'disconnected',
    levels: {},
    stages: {},
};

const getters = {
    getStagesStatus: state => {
        return state.stages_status;
    },
    getLevels: state => {
        return state.levels
    },
    getLevel: state => id => {
        return state.levels[id]
    },
    isLevelValid: state => id => {
        if (state.levels[id] !== undefined) {
            if (state.levels[id].hasOwnProperty('errors')) {
                if (Object.keys(state.levels[id].errors).length > 0) return false;
            }
        }
        return true;
    },
    isFirstLevel: state => id => {
        return state.levels[id].order == 1;
    },
    isLastLevel: state => id => {
        const maxOrder = Object.values(state.levels)
            .reduce((max, obj) => {
                return Math.max(max, obj.order)
            }, 0);
        return state.levels[id].order == maxOrder;
    },
    getMaxStageOrder: state => level => {
        return  Object.values(state.stages)
            .filter(el => el.level == level)
            .reduce((max, obj) => {
                return Math.max(max, obj.order)
            }, 0);
    },
    getStage: state => id => {
        return state.stages[id]
    },
    getStages: state => level_id => {
        return Object.values(state.stages)
            .filter(item => item.level === level_id)
            .reduce((stages, key) => {
                stages.push(state.stages[key.id]);
                return stages;
            }, [])
    },
    getAllStages: state => {
        return Object.values(state.stages)
            .reduce((stages, key) => {
                stages.push(state.stages[key.id]);
                return stages;
            }, [])
    },
    isStageValid: state => id => {
        if (state.stages[id] !== undefined) {
            if (state.stages[id].hasOwnProperty('errors')) {
                if (Object.keys(state.stages[id].errors).length > 0) return false;
            }
        }
        return true;
    },
    getStageAction: state => (stageId, id) => {
        return state.stages[stageId].action_types[id]
    }
};

const actions = {
    async [LEVELS_GET]({commit}) {
        commit(LEVELS_GET);
        try {
            const resp = await axios.get('gaming/levels');
            commit(LEVELS_GET_SUCCESS, resp.data);
            return resp;
        } catch (e) {
            commit(LEVELS_GET_ERROR);
            return e.response
        }
    },
    async ["UPDATE_LEVEL"]({commit, getters}, payload) {
        commit("UPDATE_LEVEL", payload);
        if (getters.getStagesStatus != 'loading') {
            if (getters.isLevelValid(payload.id)) {
                try {
                    const level = Object.assign({}, getters.getLevel(payload.id));
                    if (level.hasOwnProperty('isNew')) {
                        // create level
                        commit("STAGES_LOADING");
                        delete level['isNew'];
                        delete level['errors'];
                        delete level['id'];
                        delete level['stages'];
                        const resp = await axios.post('gaming/levels', level);
                        commit("CREATE_LEVEL_SUCCESS", {data: resp.data, oldId: payload.id});
                    } else {
                        // save level
                        const path = 'gaming/levels/' + payload.id;
                        await axios.patch(path, payload.fields);
                        commit("SAVE_LEVEL_SUCCESS");
                    }
                } catch (e) {
                    commit('SAVE_LEVEL_ERROR', e)
                }
            }
        }
    },
    async ["ADD_LEVEL"]({commit}) {
        commit("ADD_LEVEL")
    },
    async ["REMOVE_LEVEL"]({commit}, levelId) {
        try {
            await axios.delete('gaming/levels/' + levelId);
            commit("REMOVE_LEVEL", levelId);
        } catch (e) {
            this._vm.$log.info(e.response);
            commit('STAGES_ERROR');
        }
    },
    async ["REORDER_LEVEL"]({commit}, {id, order}) {
        commit("STAGES_LOADING");
        try {
            const fields = {order: order};
            const path = 'gaming/levels/' + id;
            await axios.patch(path, fields);
            commit("REORDER_LEVEL_SUCCESS", {id: id, order: order})
        } catch (e) {
            this._vm.$log.info(e.response);
        }
    },
    async ["UPDATE_STAGE"]({commit, getters}, payload) {
        commit("UPDATE_STAGE", payload);
        const stageId = payload.id;
        if (getters.getStagesStatus != 'loading') {
            if (getters.isStageValid(stageId)) {
                try {
                    const stage = Object.assign({}, getters.getStage(stageId));
                    if (stage.hasOwnProperty('isNew')) {
                        commit("STAGES_LOADING");
                        // create stage
                        const path = 'gaming/levels/' + stage.level + '/stages';
                        delete stage['isNew'];
                        delete stage['errors'];
                        delete stage['level'];
                        delete stage['id'];
                        const resp = await axios.post(path, stage);
                        commit("CREATE_STAGE_SUCCESS", {data: resp.data, oldId: stageId});
                    } else {
                        // save stage
                        const path = 'gaming/stages/' + stage.id;
                        await axios.patch(path, payload.fields);
                        commit("STAGES_SUCCESS");
                    }
                } catch (e) {
                    window.console.log(e);
                    commit('STAGES_ERROR');
                }
            }
        }
    },
    async ["REMOVE_STAGE"]({commit}, payload) {
        try {
            const resp = await axios.delete('gaming/stages/' + payload.stageId);
            commit("REMOVE_STAGE", payload);
            this._vm.$log.info(resp);
        } catch (e) {
            this._vm.$log.info(e.response)
        }
    },
    async ["ADD_STAGE"]({commit}, levelId) {
        commit("ADD_STAGE", {levelId: levelId});
    },
    async ["REORDER_STAGE"]({commit, getters}, {fromStage, toStage, toLevel}) {
        commit("STAGES_LOADING");
        try {
            let toOrder;
            if (Number(toStage) > 0) {
                toOrder = getters.getStage(toStage).order;
            } else {
                toOrder = getters.getMaxStageOrder(toLevel) + 1;
            }
            const data =  { order: toOrder };
            const query = "gaming/levels/" + toLevel + "/stages/" + fromStage;
            await axios.put(query, data);
            commit("REORDER_STAGE_SUCCESS", {fromStage: fromStage, toOrder: toOrder, toLevel: toLevel});
        } catch (e) {
            this._vm.$log.info(e.response);
            commit("STAGES_ERROR")
        }
    },
    async ["UPDATE_ACTION"]({commit, dispatch, getters}, payload) {
        commit("UPDATE_ACTION", payload);
        const value = getters.getStage(payload.stageId).action_types;
        dispatch("UPDATE_STAGE", {id: payload.stageId, fields: {action_types: value}})
    },
    async ["REMOVE_ACTION"]({commit, dispatch, getters}, payload) {
        commit("REMOVE_ACTION", payload);
        const value = getters.getStage(payload.stageId).action_types;
        dispatch("UPDATE_STAGE", {id: payload.stageId, fields: {action_types: value}})
    },
    async ["ADD_ACTION"]({commit, dispatch, getters}, stageId) {
        commit("ADD_ACTION", stageId);
        const value = getters.getStage(stageId).action_types;
        dispatch("UPDATE_STAGE", {id: stageId, fields: {action_types: value}})
    },
    async ["STAGES_FIX"]({commit, dispatch}) {
        commit("STAGES_LOADING")
        let query = 'gaming/stages/fix'
        await axios.post(query)
        dispatch(LEVELS_GET)
    },
    async ["STAGES_FIX_ACTION_ID"]({commit, dispatch}) {
        commit("STAGES_LOADING")
        let query = 'gaming/stages/fix'
        await axios.put(query)
        dispatch(LEVELS_GET)
    }
};

const mutations = {
    ["STAGES_LOADING"]: (state) => {
        state.stages_status = 'loading';
    },
    ["STAGES_SUCCESS"]: (state) => {
        state.stages_status = 'success';
    },
    ["STAGES_ERROR"]: (state) => {
        state.stages_status = 'error';
    },
    //================= LEVELS MUTATIONS ====================================
    [LEVELS_GET]: (state) => {
        state.stages_status = 'loading';
    },
    [LEVELS_GET_SUCCESS]: (state, data) => {
        let stages = {};
        let levels = {};
        data.map(item => {
            item.errors = {};
            levels[item.id] = item;
            item.stages.map(stage => {
                stage.errors = {};
                stages[stage.id] = stage
            })
        });
        state.levels = levels;
        state.stages = stages;
        for (const stage_id in state.stages) {
            state.stages[stage_id].errors = validateStage(state.stages[stage_id])
        }
        state.stages_status = 'success';
    },
    [LEVELS_GET_ERROR]: (state) => {
        state.stages_status = 'error'
    },

    ["UPDATE_LEVEL"]: (state, payload) => {
        const levelId = payload.id;
        let level = Object.assign(state.levels[levelId], payload.fields);
        level.errors = validateLevel(level);
        state.levels[levelId] = level;
    },
    ["ADD_LEVEL"]: (state) => {
        const maxOrder = Object.values(state.levels)
            .reduce((max, obj) => {
                return Math.max(max, obj.order)
            }, 0) + 1;
        const levelId = Object.values(state.levels)
            .reduce((max, obj) => {
                return Math.max(max, obj.id)
            }, 0) + 1;
        const level = {
            isNew: true,
            id: levelId,
            description: '',
            name: '',
            stages: [],
            order: maxOrder,
            errors: {name: true, description: true}
        };
        let levels = Object.assign({}, state.levels);
        levels[levelId] = level;
        state.levels = levels
    },
    ['REMOVE_LEVEL']: (state, levelId) => {
        let levels = Object.assign({}, state.levels);
        delete levels[levelId];
        state.levels = levels;
    },
    ["CREATE_LEVEL_SUCCESS"]: (state, payload) => {
        state.stages_status = 'success';
        let levels = Object.assign({}, state.levels);
        delete levels[payload.oldId];
        payload.data.errors = {};
        levels[payload.data.id] = payload.data;
        state.levels = levels
    },
    ["SAVE_LEVEL_SUCCESS"]: (state) => {
        state.stages_status = 'created';
    },
    ["SAVE_LEVEL_ERROR"]: (state) => {
        state.stages_status = 'saved';
    },
    ["REORDER_LEVEL_SUCCESS"]: (state, {id, order}) => {
        state.stages_status = 'success';
        let levels = Object.assign({}, state.levels);
        state.levels = {};
        const fromOrder = levels[id].order;

        state.levels = Object.values(levels).reduce((result, level) => {
            if (level.id == id){
                level.order = Number(order);
            } else {
                if (level.order > fromOrder) level.order = level.order - 1;
                if (level.order >= order) level.order = level.order + 1;
            }
            result[level.id] = level;
            return result;
        },{})
    },
    //========= STAGE MUTATIONS ====================================
    ["UPDATE_STAGE"]: (state, payload) => {
        const stageId = payload.id;
        let stage = Object.assign(state.stages[stageId], payload.fields);
        stage.errors = validateStage(stage);
        state.stages[stageId] = stage;
    },
    ["REMOVE_STAGE"]: (state, payload) => {
        const stageId = payload.stageId;
        let stages = Object.assign({}, state.stages);
        delete stages[stageId];
        state.stages = stages;
    },
    ["ADD_STAGE"]: (state, payload) => {
        const stageId = Object.values(state.stages)
            .reduce((max, obj) => {
                return Math.max(max, obj.id)
            }, 0) + 1;
        const stage = {
            isNew: true,
            id: stageId,
            action_types: [],
            duration: 1,
            description: "",
            goal: 1,
            title: "",
            level: payload.levelId,
            order: 100,
            errors: {title: true, action_types: true, message: true},
            message: {
                type: undefined
            }
        };
        let stages = Object.assign({}, state.stages);
        stages[stageId] = stage;
        state.stages = stages;
    },
    ["VALIDATE_STAGE"]: (stage, stageId) => {
        state.stages[stageId].errors = validateStage(state.stages[stageId])
    },
    ["CREATE_STAGE_SUCCESS"]: (state, payload) => {
        state.stages_status = 'success';
        let stages = Object.assign({}, state.stages);
        delete stages[payload.oldId];
        payload.data.errors = {};
        stages[payload.data.id] = payload.data;
        state.stages = stages;
    },
    ["REORDER_STAGE_SUCCESS"]: (state, {fromStage, toOrder, toLevel}) => {
        state.stages_status = 'success';
        const tmpstages = Object.assign({}, state.stages);
        let levels = Object.assign({}, state.levels);
        state.levels = {};
        state.stages = {};
        const fromLevel = tmpstages[fromStage].level;
        const fromOrder = tmpstages[fromStage].order;
        state.stages = Object.values(tmpstages).reduce((result, stage) => {
            if (stage.id == fromStage) {
                stage.order = Number(toOrder);
                stage.level = Number(toLevel);
            } else {
                if (stage.level == fromLevel) {
                    if (stage.order > fromOrder) stage.order = stage.order - 1;
                }
                if (stage.level == toLevel) {
                    if (stage.order >= toOrder) stage.order = stage.order + 1;
                }
            }
            result[stage.id] = stage;
            return result;
        }, {});
        state.levels = levels;
    },
    //=========== ACTION MUTATIONS =======================================
    ["UPDATE_ACTION"]: (state, payload) => {
        const stageId = payload.stageId;
        const index = payload.index;
        let action = Object.assign(state.stages[stageId].action_types[index], payload.fields);
        state.stages[stageId].action_types.splice(index, 1);
        action.errors = validateAction(action);
        state.stages[stageId].action_types.splice(index, 0, action);
    },
    ["REMOVE_ACTION"]: (state, payload) => {
        const stageId = payload.stageId;
        const index = payload.index;
        state.stages[stageId].action_types.splice(index, 1)
    },
    ["ADD_ACTION"]: (state, stageId) => {
        const action = {
            action: 'glucose',
            name: 'default',
            reward: 1,
            start_time: '0:01',
            end_time: '23:59',
            weekdays: [],

        };
        state.stages[stageId].action_types.push(action);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
