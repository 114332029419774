import {
    AUTH_LOGOUT,
    USER_REQUEST,
    USER_ERROR,
    USER_SUCCESS,
    USER_CREATE,
    USER_GET,
    USER_GET_GLUCOSE_CALENDAR,
    USER_ONMESSAGE,
    USER_ONREAD,
    USER_SET_LAST_UPDATE
} from '../constants';

import {getAxios} from '../../api'
const axios = getAxios();

const initialState = {
    user_status: '',
    user_data: [],
    user_data_expire: 0,
    last_update: 0,
    glucose_daily_averages: [],
    glucose_daily_averages_start: 'start',
    glucose_daily_averages_end: 'end',
    blood_pressure_daily_averages: [],
    user_number_averages_days: 0,
    total_users: 0,
    current_page: 1,
};

export const state = {...initialState};


const getters = {
    getUsers: state => {
        return state.user_data
    },
    getUserById: state => {
        return id => state.user_data.find(user => {
            return user.user_id == id
        })
    },
    getUsersExpire: state => {
        return state.user_data_expire;
    },
    getUsersLastUpdate: state => {
        return state.last_update;
    },
    getUsersStatus: state => {
        return state.user_status;
    },
    getNumberOfDaysAverage: state => {
        return state.user_number_averages_days;
    },
    getUserGlucoseDailyAverages: state => {
        return state.glucose_daily_averages
    },
    getUserGlucoseDailyAveragesStart: state => {
        return state.glucose_daily_averages_start
    },
    getUserGlucoseDailyAveragesEnd: state => {
        return state.glucose_daily_averages_end
    },
    getUserBloodPressureDailyAverages: state => {
        return state.blood_pressure_daily_averages
    },
    getTotalUsers: state => {
        return state.total_users
    },
    getCurrentPage: state => {
        return state.current_page
    },
};


export const actions = {
    [USER_REQUEST]({commit}, user) {
        commit(USER_REQUEST);
        commit(USER_SUCCESS, user);
    },
    // user create action
    async [USER_CREATE](context, user) {
        try {
            context.commit(USER_CREATE);
            const resp = await axios.post('users', user);
            this._vm.$log.debug(resp.headers);
            context.commit(USER_SUCCESS, resp);
            return resp;
        } catch (e) {
            window.console.warn(e);
            context.commit(USER_ERROR);
            return e.response;
        }
    },
    // get user list action
    async [USER_GET](context, payload) {
        try {
            context.commit(USER_GET);
            const resp = await axios.get('users', {params: payload});
            this._vm.$log.debug(resp);
            context.commit(USER_SUCCESS, resp);
            return resp;
        } catch (e) {
            this._vm.$log.error(e.response);
            context.commit(USER_ERROR);
            return e.response
        }
    },
    async ['USER_GET_HELSINKI'](context) {
        try {
            context.commit(USER_GET);
            const resp = await axios.get('users/helsinki');
            this._vm.$log.debug(resp);
            context.commit(USER_SUCCESS, resp);
            return resp;
        } catch (e) {
            this._vm.$log.error(e.response);
            context.commit(USER_ERROR);
            return e.response
        }
    },
    async ['USER_GET_BY_ID']({commit}, user_id) {
        try {
            commit(USER_GET);
            const resp = await axios.get('users/'+ user_id);
            this._vm.$log.debug(resp);
            commit('USER_SUCCESS_GET_BY_ID', resp);
            return resp;
        } catch (e) {
            this._vm.$log.error(e.response);
            commit(USER_ERROR);
            return e.response
        }
    },
    async ['USER_COMPLETE']({commit}, user_id) {
        try {
            commit(USER_CREATE); // actualy edit
            const resp = await axios.post('clients/'+ user_id +'/complete');
            this._vm.$log.debug(resp);
            commit('USER_COMPLETE_SUCCESS', user_id);
            return resp;
        } catch(e) {
            this._vm.$log.error(e.response);
            commit(USER_ERROR);
            return e.response
        }
    },
    async [USER_GET_GLUCOSE_CALENDAR](context, {user_id, start, end}) {
        try {
            //clients/<str:client_id>/glucose/<str:start_date>/<str:end_date>
            const str = 'clients/' + user_id + '/glucose/' + start + '/' + end;
            //this._vm.$log.info(str);
            const resp = await axios.get(str);
            //this._vm.$log.debug(resp.data);
            return resp.data;
        } catch (e) {
            this._vm.$log.error(e.response);
            return e.response;
        }
    },
    async ['USER_GET_GLUCOSE_DAILY_AVERAGES'](context, {user_id, start, end, number}){
        try {
            const str = 'clients/' + user_id + '/glucose_avg/' + start + '/' + end;
            const resp = await axios.get(str);
            await context.commit('USER_SET_GLUCOSE_DAILY_AVERAGES', {data: resp.data, start: start, end: end, number: number});
            return resp.data;
        } catch (e) {
            this._vm.$log.error(e.response);
            return e.response;
        }
    },
    async ['USER_GET_BLOOD_PRESSURE_DAILY_AVERAGES'](context, {user_id, start, end}){
        try {
            const str = 'clients/' + user_id + '/blood_pressure_avg/' + start + '/' + end;
            const resp = await axios.get(str);
            await context.commit('USER_SET_BLOOD_PRESSURE_DAILY_AVERAGES', {data: resp.data, start: start, end: end});
            return resp.data;
        } catch (e) {
            this._vm.$log.error(e.response);
            return e.response;
        }
    },
    async [USER_SET_LAST_UPDATE]({commit}, time){
        commit(USER_SET_LAST_UPDATE, time)
    },
    async ['USER_SET_EXPERIMENT'](context, {user_id, experiment}){
        try {
            const str = 'clients/' + user_id + '/experiment';
            const resp = await axios.post(str, { experiment: experiment });
            return resp.data
        }  catch (e) {
            this._vm.$log.error(e.response);
            return e.response;
        }
    },
    async ['USER_DELETE'](context, user_id){
        try {
            context.commit('USER_REQUEST');
            const str = 'users';
            const resp = await axios.delete(str, { data: { client: user_id } });
            context.commit('USER_DELETE_SUCCESS', user_id);
            return resp
        }  catch (e) {
            this._vm.$log.error(e.response);
            return e.response;
        }
    }
};

const mutations = {
    [USER_SET_LAST_UPDATE](state, time) {
        state.last_update = time;
    },
    [USER_REQUEST](state) {
        state.user_status = "loading";

    },
    [USER_CREATE](state) {
        state.user_status = "creation"
    },
    [USER_GET](state) {
        state.user_status = "getting";
    },
    [USER_SUCCESS](state, resp) {
        if (state.user_status === "getting") {
            state.user_data = resp.data.results;
            state.total_users = resp.data.total;
            for( let index in state.user_data) {
                if (state.user_data[index].chats.find(el=>el.type === 0)){
                    state.user_data[index].unread = state.user_data[index].chats.find(el=>el.type === 0).unread;
                } else {
                    state.user_data[index].unread = 0;
                }
            }
            const date = new Date();
            state.user_data_expire = date.getTime() + 1000 * 60 * 15; // +15min expiration
        }
        if (state.user_status === "creation") {
            state.user_data_expire = 0;
        }
        state.user_status = "success"
    },
    ['USER_SUCCESS_GET_BY_ID'](state, resp) {
        let noRecord = true;
        state.user_data_expire = 0;

        state.user_data = state.user_data.map(function(user){
            if (user.user_id == resp.data.user_id) {
                noRecord = false;
                return resp.data;
            }
            return user
        })
        if (noRecord) {
            state.user_data.push(resp.data);
        }
        state.user_status = "success";
    },
    ['USER_COMPLETE_SUCCESS'](state, user_id) {
        state.user_data = state.user_data.map(function(user){
            if (user.user_id == user_id) {
                user.user_metadata.status='finish_questionnaire'
            }
            return user
        })
        state.user_status = "success";
    },
    [USER_ONMESSAGE](state, message) {
        this._vm.$log.info('USER_ONMESSAGE');

        for (let index in state.user_data) {
            if (state.user_data[index].chats.length > 0) {
                if (state.user_data[index].chats.find(el=>el.type === 0).id === message.room_id) {
                    //this._vm.$log.info(state.user_data[index].chats.find(el=>el.type === 0).id);
                    let user = JSON.parse(JSON.stringify(state.user_data[index]));
                    state.user_data.splice(index,1);
                    user.chats.find(el=>el.type === 0).unread += 1;
                    user.unread +=1;
                    state.user_data.push(user);
                    return;
                }
            }
        }
    },
    [USER_ONREAD](state) {
        this._vm.$log.info('USER_ONREAD');
        state.user_data_expire = 0
    },
    [USER_ERROR](state) {
        state.user_status = "error"
    },
    [AUTH_LOGOUT](state) {
        state.profile = {}
    },
    ['USER_SET_GLUCOSE_DAILY_AVERAGES'](state, payload) {
        state.glucose_daily_averages = Object.assign(state.glucose_daily_averages, payload.data);
        state.glucose_daily_averages_start = payload.start;
        state.glucose_daily_averages_end = payload.end;
        state.user_number_averages_days = payload.number;
    },
    ['USER_SET_BLOOD_PRESSURE_DAILY_AVERAGES'](state, payload) {
        state.blood_pressure_daily_averages = Object.assign(state.blood_pressure_daily_averages, payload.data)
    },
    ['USER_GET_QUESTIONNAIRE_SUCCESS'](state, payload) {
        state.user_questionnaires = payload
    },
    ['USER_DELETE_SUCCESS'](state, user_id) {
        state.user_status = "success"
        state.user_data = state.user_data.filter(function(user){
            return user.user_id != user_id
        })
    },
    ['USER_PAGINATION_SET_CURRENT_PAGE'](state, page) {
        state.current_page = page
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
