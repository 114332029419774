import {TRANSLATIONS_GET, TRANSLATION_GET, TRANSLATION_EDIT, TRANSLATION_CREATE} from "../constants";

import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    translations: []
};

const getters = {};

const actions = {
    async [TRANSLATIONS_GET]({commit}, {entity, entity_id}) {
        try {
            let str = '/' + entity + '/' + entity_id + '/translation';
            const resp = await axios.get(str);
            commit(TRANSLATIONS_GET,resp.data);
            return resp
        } catch (e) {
            this._vm.$log.error(e);
            return e.response
        }
    },
    async [TRANSLATION_GET]({commit}, {entity, entity_id, locale}) {
        try {
            let str = '/' + entity + '/' + entity_id + '/translation/' + locale;
            const resp = await axios.get(str);
            commit(TRANSLATIONS_GET,resp.data);
            return resp
        } catch (e) {
            this._vm.$log.error(e);
            return e.response
        }
    },
    async [TRANSLATION_CREATE]({commit}, {entity, entity_id, locale, data}) {
        try {
            let str = '/' + entity + '/' + entity_id + '/translation/' + locale;
            const resp = await axios.post(str, data);
            commit(TRANSLATION_CREATE);
            return resp
        } catch (e) {
            this._vm.$log.error(e);
            return e.response
        }
    },
    async [TRANSLATION_EDIT]({commit}, {entity, entity_id, locale, data}) {
        try {
            let str = '/' + entity + '/' + entity_id + '/translation/' + locale;
            const resp = await axios.put(str, data);
            commit(TRANSLATION_EDIT);
            return resp
        } catch (e) {
            this._vm.$log.error(e);
            return e.response
        }
    },
};

const mutations = {
    [TRANSLATIONS_GET](state, translations) {
        state.translations = translations;
        state.status = 'ok'
    },
    [TRANSLATION_CREATE](state) {
        state.status = 'ok'
    },
    [TRANSLATION_EDIT](state) {
        state.status = 'ok'
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}