
import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    medicine_items_status: 'disconnected',
    medicine_items:{}
}

const getters = {
    getMedicineItemsStatus: state => {
        return state.medicine_items_status
    },
    getMedicineItems: state => {
        return state.medicine_items
    }
}

const actions = {
    async ['MEDICINE_ITEMS_LIST_GET']({commit}) {
        commit('MEDICINE_ITEMS_LOADING');
        try {
            const resp = await axios.get('reports/medicine_items');
            commit('MEDICINE_ITEMS_GET_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('MEDICINE_ITEMS_ERROR');
            return e.response
        }
    },
    async ["MEDICINE_ITEMS_CREATE"]({commit}, item) {
        commit('MEDICINE_ITEMS_LOADING');
        try {
            const resp = await axios.post('reports/medicine_items', item);
            commit('MEDICINE_ITEMS_CREATE_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('MEDICINE_ITEMS_ERROR');
            return e.response
        }
    },
    async ["MEDICINE_ITEMS_EDIT"]({commit}, item) {
        commit('MEDICINE_ITEMS_LOADING');
        try {
            const resp = await axios.post('reports/medicine_item/'+ item.id, item);
            commit('MEDICINE_ITEMS_EDIT_SUCCESS', resp.data);
            return resp;
        } catch (e) {
            commit('MEDICINE_ITEMS_ERROR');
            return e.response
        }
    },
    async ["MEDICINE_ITEMS_DELETE"]({commit}, id) {
        commit('MEDICINE_ITEMS_LOADING');
        try {
            const resp = await axios.delete('reports/medicine_item/'+ id);
            commit('MEDICINE_ITEMS_DELETE_SUCCESS', id);
            return resp;
        } catch (e) {
            commit('MEDICINE_ITEMS_ERROR');
            return e.response
        }
    },
}

const mutations = {
    ['MEDICINE_ITEMS_LOADING']: (state) => {
        state.medicine_items_status = 'loading';
    },
    ['MEDICINE_ITEMS_GET_SUCCESS']: (state, payload) => {
        state.medicine_items_status = 'success';
        state.medicine_items = payload;
    },
    ['MEDICINE_ITEMS_ERROR']:(state) => {
        state.medicine_items_status = 'error';
    },
    ['MEDICINE_ITEMS_CREATE_SUCCESS']: (state, payload) => {
        state.medicine_items.push(payload)
    },
    ['MEDICINE_ITEMS_EDIT_SUCCESS']: (state, payload) => {
        let medicine_items = JSON.parse(JSON.stringify(state.medicine_items))
        let id = payload.id
        medicine_items = medicine_items.filter(function (el) {if (el.id !== id) return el})
        medicine_items.push(payload)
        state.medicine_items= medicine_items
        state.medicine_items_status='suxxess'
    },
    ['MEDICINE_ITEMS_DELETE_SUCCESS']: (state, id ) => {
        let medicine_items = JSON.parse(JSON.stringify(state.medicine_items))
        medicine_items = medicine_items.filter(function (el) {if (el.id !== id) return el})
        state.medicine_items= medicine_items
        state.medicine_items_status = 'success'
    },

}

export default {
    state,
    getters,
    actions,
    mutations
};