import {
    QUESTIONARY_CREATE,
    QUESTIONARY_START,
    QUESTIONARY_DELETE,
    QUESTIONARY_ERROR,
    QUESTIONARY_GET,
    QUESTIONARY_SUCCESS,
    QUESTIONARY_EDIT, QUESTIONARY_USE_AT_START,
    QUESTIONARY_USE_AT_FINISH, QUESTIONARY_USE_DAILY,
    DAILY_QUESTIONARY_DELETE
} from "../constants";

import {getAxios} from '../../api'
const axios = getAxios();


const state = {
    status: ''
};

const getters = {};

const actions = {
    async [QUESTIONARY_GET]({commit}) {
        commit(QUESTIONARY_GET);
        try {
            const path = 'questioner/questioners';
            const resp = await axios.get(path);
            //this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_START]({commit}, qid, uid) {
        commit(QUESTIONARY_START);
        try {
            const path = 'questioner/questioners/' + qid + '/start/' + uid;
            const resp = await axios.get(path);
            //this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_CREATE]({commit}, questionary) {
        commit(QUESTIONARY_CREATE);
        try {
            const path = 'questioner/questioners';
            const resp = await axios.post(path, questionary);
            //this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_EDIT]({commit}, questionary) {
        commit(QUESTIONARY_CREATE);
        try {
            const path = 'questioner/questioners/' + questionary.id;
            const resp = await axios.post(path, questionary);
            //this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_USE_AT_START]({commit}, id) {
        try {
            const path = 'questioner/questioners/' + id + '/use_as_start';
            const resp = await axios.post(path);
            this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_USE_AT_FINISH]({commit}, id) {
        try {
            const path = 'questioner/questioners/' + id + '/use_as_finish';
            const resp = await axios.post(path);
            this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_USE_DAILY]({commit}, id) {
        try {
            const path = 'questioner/questioners/' + id + '/use_as_daily';
            const resp = await axios.post(path);
            this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [QUESTIONARY_DELETE]({commit}, id) {
        try {
            commit(QUESTIONARY_DELETE);
            const path = 'questioner/questioners/' + id;
            const resp = await axios.delete(path);
            //this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    },
    async [DAILY_QUESTIONARY_DELETE]({commit}) {
        try {
            commit(QUESTIONARY_DELETE);
            const path = 'questioner/questioners';
            const resp = await axios.delete(path);
            //this._vm.$log.debug(resp);
            commit(QUESTIONARY_SUCCESS);
            return resp;
        } catch (e) {
            commit(QUESTIONARY_ERROR);
            return e.response
        }
    }
};

const mutations = {
    [QUESTIONARY_GET]: (state) => {
        state.state = 'getting';
    },
    [QUESTIONARY_SUCCESS]: (state) => {
        state.status = 'success';
    },
    [QUESTIONARY_ERROR]: (state) => {
        state.status = 'error'
    },
    [QUESTIONARY_CREATE]: (state) => {
        state.status = 'creation'
    },
    [QUESTIONARY_DELETE]: (state) => {
        state.status = 'delete'
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
}



