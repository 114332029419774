import {getAxios} from '../../api'
import moment from "moment";

const axios = getAxios();

function waitLevels(getters) {
    if (getters.getStagesStatus ==='success') return;
    setTimeout(waitLevels, 250);
    return;
}

const validateAction = fields => {
    let errors = {};
    if (!(fields['reward'] > 0)) errors['reward'] = true;
    const start = new Date(moment(fields['start_time'], 'HH:mm'));
    const end = new Date(moment(fields['end_time'], 'HH:mm'));
    if (start >= end) errors['time'] = true;
    return errors;
};


const state = {
    client_stages_status: 'disconnected',
    client_stages: {},
    client_stages_client: '',
    client_compiled: {},
}

const getters = {
    getClientStagesStatus: state => state.client_stages_status,
    getClientStage: state => id => {
        return state.client_compiled[id]
    },
    getClientStageAction: state => (stageId, id) => {
        if (!state.client_compiled[stageId].hasOwnProperty('action_modifiers')){
            return undefined;
        }
        return state.client_compiled[stageId].action_modifiers[id]
    }
}

const actions = {
    async ['CLIENT_STAGES_GET']({commit,dispatch, getters}, user_id) {
        commit('CLIENT_STAGES_GET');
        try {
            dispatch('LEVELS_GET');
            const path = 'gaming/personal_actions/' + user_id;
            const resp = await axios.get(path);
            waitLevels(getters);
            commit('CLIENT_STAGES_GET_SUCCESS', {data: resp.data,  user_id: user_id, getters:getters});
            return resp;
        } catch (e) {
            commit('CLIENT_STAGES_GET_ERROR');
            return e.response
        }
    },
    async ["CLIENT_UPDATE_STAGE"]({commit, getters}, payload) {
        commit("CLIENT_UPDATE_STAGE", payload);
        const stageId = payload.id;

        let personalActions = JSON.parse(JSON.stringify(payload.fields));
        let actionModifiers = [];
        for(let action of personalActions.action_modifiers){
            if (action.meta!=='nothing') {
                let newAction = JSON.parse(JSON.stringify(action))
                delete newAction['errors'];
                actionModifiers.push(newAction);
            }
        }
        personalActions.action_modifiers = actionModifiers;

        if (getters.getClientStagesStatus != 'loading') {
            if (getters.isStageValid(stageId)) {
                try {
                    let stage = Object.assign({}, getters.getClientStage(stageId));
                    if (stage.hasOwnProperty('id')) {
                        // save stage
                        const path = 'gaming/personal_actions/' + stage.id;
                        await axios.put(path, personalActions);
                        commit("CLIENT_STAGES_SUCCESS");
                    } else {
                        commit("CLIENT_STAGES_LOADING");
                        // create stage
                        const path = 'gaming/personal_actions/' + stage.user
                        stage.action_modifiers = actionModifiers;
                        //delete stage.user
                        const resp = await axios.post(path, stage);
                        commit("CLIENT_CREATE_STAGE_SUCCESS", {data: resp.data, oldId: stageId});
                    }
                } catch (e) {
                    window.console.log(e);
                    commit('STAGES_ERROR');
                }
            }
        }
    },
    async ["CLIENT_ADD_ACTION"]({commit, dispatch, getters}, stageId) {
        commit("CLIENT_ADD_ACTION", stageId);
        const value = getters.getClientStage(stageId).action_modifiers;
        dispatch("CLIENT_UPDATE_STAGE", {id: stageId, fields: {action_modifiers: value}})
    },
    async ["CLIENT_REMOVE_ACTION"]({commit, dispatch, getters}, payload) {
        commit("CLIENT_REMOVE_ACTION", payload);
        const value = getters.getClientStage(payload.stageId).action_modifiers;
        dispatch("CLIENT_UPDATE_STAGE", {id: payload.stageId, fields: {action_modifiers: value}})
    },
    async ["CLIENT_UPDATE_ACTION"]({commit, dispatch, getters}, payload) {
        commit("CLIENT_UPDATE_ACTION", payload);
        const value = getters.getClientStage(payload.stageId).action_modifiers;
        dispatch("CLIENT_UPDATE_STAGE", {id: payload.stageId, fields: {action_modifiers: value}})
    },
}

const mutations = {
    ['CLIENT_STAGES_LOADING']:(state)=>{
        state.client_stages_status = 'loading';
    },
    ['CLIENT_STAGES_SUCCESS']:(state)=>{
        state.client_stages_status = 'success';
    },
    ['CLIENT_STAGES_GET']: (state) => {
        state.client_stages_status = 'loading';
    },
    ['CLIENT_STAGES_GET_SUCCESS']: (state, {data, user_id, getters}) => {
        state.client_stages = data;
        state.client_stages_client = user_id;
        state.client_stages_status = 'success';
        const stages = getters.getAllStages;
        let compiled_stages = {};
        for (let stage of stages) {
            const clientStage = state.client_stages.find(el => el.stage_id === stage.id)
            let compiled = [];
            let clientActions;
            if (clientStage === undefined) {
                clientActions = undefined;
            } else {
                clientActions = clientStage.action_modifiers
            }
            for (let action of stage.action_types) {
                let modify = undefined;
                if (clientActions !== undefined) {
                    if (action.hasOwnProperty('id')) {
                        modify = clientActions.find(el => el.id === action.id)
                    }
                }
                if (modify === undefined) {
                    let newAction = JSON.parse(JSON.stringify(action));
                    newAction['meta'] = 'nothing';
                    compiled.push(newAction);

                } else {
                    compiled.push(modify)
                }
            }
            if (clientActions !== undefined) {
                for(let clientAction of clientActions) {
                    if (clientAction.meta === 'add') {
                        compiled.push(clientAction)
                    }
                }
            }
            const compiled_stage = {
                stage_id: stage.id,
                user: user_id,
                action_modifiers: compiled
            }
            if (clientStage!==undefined){
                compiled_stage.id = clientStage.id;
            }
            compiled_stages[stage.id] = compiled_stage;
        }
        state.client_compiled = compiled_stages;
    },
    ['CLIENT_STAGES_GET_ERROR']: (state) => {
        state.client_stages_status = 'error';
    },
    ["CLIENT_UPDATE_STAGE"]: (state, payload) => {
        const stageId = payload.id;
        let stage = Object.assign(state.client_compiled[stageId], payload.fields);
        state.client_compiled[stageId] = stage;
    },
    ["CLIENT_CREATE_STAGE_SUCCESS"]: (state, payload) => {
        state.client_stages_status = 'success';
        let stages = Object.assign({}, state.client_compiled);
        delete stages[payload.data.stage_id];
        stages[payload.data.stage_id] = payload.data;
        state.client_compiled = stages;
    },
    //=========================== ACTIONS ================================
    ["CLIENT_ADD_ACTION"]: (state, stageId) => {
        const action = {
        meta: 'add',
        action: 'nutrition',
        reward: 1,
        start_time: '0:01',
        end_time: '23:59'
        };
        state.client_compiled[stageId].action_modifiers.push(action);
    },
    ["CLIENT_REMOVE_ACTION"]: (state, payload) => {
        const stageId = payload.stageId;
        const index = payload.index;
        state.client_compiled[stageId].action_modifiers.splice(index, 1)
    },
    ["CLIENT_UPDATE_ACTION"]: (state, payload) => {
        const stageId = payload.stageId;
        const index = payload.index;
        let action = Object.assign(state.client_compiled[stageId].action_modifiers[index], payload.fields);
        state.client_compiled[stageId].action_modifiers.splice(index, 1);
        action.errors = validateAction(action);
        state.client_compiled[stageId].action_modifiers.splice(index, 0, action);
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}