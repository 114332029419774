import {getAxios} from '../../api'
const axios = getAxios();

const state =  {
    status: String,
    exams: [],
};

const getters = {
    getExams: state => {
        return state.exams;
    },
    getState: state => {
        return state.status;
    },
};

const actions = {
    async ["GET_MEDICAL_EXAMS"]({commit}, user_id) {
        try {
            const str = 'reports/medical/'+ user_id;
            const resp = await axios.get(str);
            this._vm.$log.debug(resp);
            commit("GET_MEDICAL_EXAM_SUCCESS", resp.data);
        } catch (e) {
            this._vm.$log.error(e.response);
        }
    }
};

const mutations = {
    ["GET_MEDICAL_EXAM_SUCCESS"](state, payload) {
        state.exams = payload;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
}